export function parseEpc(code: string) {
    let result = '';
    for (let i = 0; i < code.length; i += 2) {
        let char = String.fromCharCode(parseInt(code.substring(i, i + 2), 16));
        if (char === '\0') {
            break;
        }
        result += char;
    }
    return result;
}

export function parseCode(code: string) {
    let result = '';
    for (let i = 0; i < code.length; i ++) {
        result += code.charCodeAt(i).toString(16);
    }
    return result.toUpperCase() + (result.length % 4 ? '00' : '');
}

export function parseSku(code: string) {
    if (!code.includes('-')) {
        code = parseEpc(code);
    }
    let parts = code.split('-');
    if (parts.length >= 4) {
        parts.pop();
        parts.pop();
        parts.pop();
        if (parts[parts.length - 1] === '7000') {
            parts.pop();
        }
    }
    return parts.join('-');
}

export interface ModalRef<T> {
    show: (data: T) => void;
    hide: () => void;
}
